.drop-menu {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 13;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgb(17, 17, 17);
  color: lightgray;
  width: 100%;
  height: 100%;
  border-bottom: solid;
  border-width: 1px;
}

.dropdown-link-home {
  text-decoration: none;
  color: lightgray;
  width: 100%;
  height: 50%;
  text-align: center;
}

.dropdown-link-about {
  text-decoration: none;
  color: lightgray;
  width: 100%;
  height: 50%;
}
