.form-class {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 3;
  grid-row-end: 4;
  display: flex;
  flex-direction: column;
}

.drop-items {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 100%;
}

.drop-items:hover {
  cursor: pointer;
  background-color: rgb(73, 147, 207);
}
.form-container {
  height: 250px;
  display: flex;
  flex-direction: column;
}

.form-description {
  color: lightgray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tablet-p {
  display: none;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.form-top {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40%;
  color: lightgray;
}

.text-input {
  width: 100px;
  height: 20px;
}

.text-input-two {
  width: 100px;
  height: 20px;
  border: 2px solid rgba(233, 51, 51, 0.76);
  border-radius: 2px;
}

.text-input-two:focus {
  outline-color: red;
}
.error-div {
  color: rgba(233, 51, 51, 0.76);
  position: absolute;
}

.error-none {
  display: none;
}

.radio-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 40%;
  color: lightgray;
}
.radio-sub-container {
  display: flex;
  justify-content: space-between;
  width: 108px;
  margin-right: 40px;
}

.labelsClass {
  margin-left: 10px;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20%;
}

.button-submit {
  background-color: rgb(73, 147, 207);
  color: lightGray;
  border: none;
  width: 108px;
  height: 100%;
  cursor: pointer;
}

.button-submit:active {
  transform: translateY(2px);
}

.quotes {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
}

.table {
  margin-top: 20px;
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.tableHead {
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  background-color: rgb(18, 107, 180);
  color: lightGray;
}

.tableRows {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.th {
  width: 20%;
}

.tableBody {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50%;
  width: 100%;
  color: lightgray;
}

.td {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

#volume-id {
  display: flex;
  justify-content: center;
  word-break: break-all;
  align-items: center;
}

.Graph-container {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 4;
  grid-row-end: 5;
  background-color: black;
}

.mini {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .form-class {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    min-height: 349px;
  }

  .form-container {
    height: 75%;
  }

  .form-description {
    display: none;
  }
  .tablet-p {
    display: flex;
    color: lightgray;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .radio-container {
    display: flex;
    justify-content: space-evenly;
  }

  .radio-sub-container {
    width: min-content;
    margin: 0;
  }

  #radioOne {
    cursor: pointer;
  }

  #radioTwo {
    cursor: pointer;
  }

  #radioThree {
    cursor: pointer;
  }

  .label-stdv {
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quotes {
    height: 25%;
  }

  .th {
    font-size: 0.8rem;
  }
  .td {
    font-size: 0.8rem;
  }

  .Graph-container {
    grid-column-start: 5;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 4;
  }
}

@media screen and (min-width: 1024px) {
  .form-class {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .form-container {
    height: 75%;
  }

  .quotes {
    height: 25%;
  }

  .Graph-container {
    grid-column-start: 4;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 4;
  }
}

@media screen and (min-width: 1440px) {
  .form-class {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    min-height: 349px;
  }

  .form-container {
    height: 75%;
  }

  .table {
    margin: 0;
  }
  .quotes {
    height: 25%;
  }

  .td {
    font-size: 1rem;
  }

  .Graph-container {
    grid-column-start: 4;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 4;
  }
}
