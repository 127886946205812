.nav-bar {
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(18, 107, 180);
}

.menu-button-container {
  height: 30px;
  width: 30px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
}

.burger-one {
  width: 100%;
  height: 3px;
  background-color: lightgray;
}

.burger-two {
  width: 100%;
  height: 3px;
  background-color: lightgray;
}

.burger-three {
  width: 100%;
  height: 3px;
  background-color: lightgray;
}

.hamburger-button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.navbar-buttons {
  display: none;
}

.link-one {
  display: none;
  color: lightgray;
}
.link-two {
  display: none;
  color: lightgray;
}

.nav-title {
  color: lightgray;
  font-size: 18px;
}

.empty-space {
  width: 30px;
  margin-right: 5px;
}

.home-link {
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.home-link:visited {
  text-decoration: none;
}

.about-link {
  width: 100%;
  height: 100%;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .nav-bar {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 50px;
  }

  .menu-button-container {
    display: none;
  }
  .nav-title {
    grid-column-start: 5;
    grid-column-end: 9;
    text-align: center;
  }
  .navbar-buttons {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: red;
    justify-content: space-evenly;
    align-items: center;
    color: lightgray;
    width: 100%;
    height: 100%;
    background-color: rgb(18, 107, 180);
  }

  .link-one {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .link-one:hover {
    background-color: rgb(52, 133, 199);
  }
  .link-two {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
  }
  .link-two:hover {
    background-color: rgb(52, 133, 199);
  }
}

@media screen and (min-width: 1024px) {
  .navbar-buttons {
    display: flex;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    background-color: red;
    justify-content: space-evenly;
    align-items: center;
    color: lightgray;
    width: 100%;
    height: 100%;
    background-color: rgb(18, 107, 180);
  }
}
