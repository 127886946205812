.table-class {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 5;
  grid-row-end: 6;
}

.mainTable {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.thead-space {
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(18, 107, 180);
  color: lightgray;
}

#mainTbody {
  overflow-y: auto;
  height: 300px;
}

.tr-space-even {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#adjust-head-tr {
  height: 100%;
}

.th-space {
  border: none;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-width: 1px;
  width: 33%;
  height: 100%;
  text-align: top;
  display: flex;
  align-items: center;
  justify-content: center;
}

.td-borders {
  width: 33%;
  text-align: center;
  color: lightgray;
}

@media screen and (min-width: 768px) {
  .table-class {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .mainTable {
    height: 100%;
  }
  #mainTbody {
    height: 349px;
  }
}

@media screen and (min-width: 1024px) {
  .table-class {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}

@media screen and (min-width: 1440px) {
  .table-class {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  #mainTbody {
    height: 490px;
  }
}
