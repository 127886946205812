.description-container {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 3;
  grid-row-end: 4;
  text-align: center;
}

.description-h {
  color: lightgray;
}

.description-p {
  color: lightgray;
}

.formula {
  color: lightgray;
}

.About-tile-one {
  background-color: rgb(18, 107, 180);
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 4;
  grid-row-end: 5;
  text-align: center;
  box-shadow: 5px 5px 5px rgb(49, 53, 56);
}

.About-tile-two {
  background-color: rgb(18, 107, 180);
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 5;
  grid-row-end: 6;
  text-align: center;
  box-shadow: 5px 5px 5px rgb(49, 53, 56);
}

.About-tile-three {
  background-color: rgb(18, 107, 180);
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 6;
  grid-row-end: 7;
  text-align: center;
  box-shadow: 5px 5px 5px rgb(49, 53, 56);
}

.About-link-tiles {
  background-color: rgb(18, 107, 180);
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 7;
  grid-row-end: 8;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  box-shadow: 5px 5px 5px rgb(49, 53, 56);
}

.link-description {
  color: lightgray;
  text-align: center;
}

.image-one-container {
  width: 100%;
  height: 30%;
}

.image-two-container {
  width: 100%;
  height: 30%;
}

.image-three-container {
  width: 100%;
  height: 30%;
}

.image-one {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-one:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.image-two {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  background-color: white;
}

.image-two:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.image-three {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  background-color: white;
}

.image-three:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.all-images {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media screen and (min-width: 768px) {
  .About-link-tiles {
    height: 7rem;
  }

  .link-description {
    width: 100%;
    height: 20%;
  }

  .all-images {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .image-one-container {
    width: 30%;
    height: 50%;
  }

  .image-two-container {
    width: 30%;
    height: 50%;
  }
  .image-three-container {
    width: 30%;
    height: 50%;
  }
}
