body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(17, 17, 17);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 50px 0px 325px 300px 345px;
}

@media screen and (min-width: 768px) {
  #root {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 50px 1fr 1fr;
  }
}

@media screen and (min-width: 1440px) {
  #root {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 50px 0.8fr 1fr;
  }
}
